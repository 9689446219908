import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isLoading = new BehaviorSubject(false);
  isLoading$ = this.isLoading.asObservable();
  private label = new BehaviorSubject<string>('Loading');
  label$ = this.label.asObservable();

  hideLoader = (): void => {
    this.isLoading.next(false);
  };

  showLoader = (label: string = null): void => {
    this.isLoading.next(true);
    this.label.next(label || 'Loading');
  };
}
